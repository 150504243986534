<template>
  <div>
    <div class="pagePadding">
      <Form inline class="formMarginBtm20">
        <FormItem>
          <span>产品编号：</span>
          <Input placeholder="请输入" v-model="queryFrom.product_model_code" class="iviewIptWidth250" />
        </FormItem>
        <!-- <FormItem class="marginLeft40">
          <span>产品名称：</span>
          <Select placeholder="请选择" @on-change="getSKUSelect" v-model="queryFrom.product_name" clearable filterable @on-clear="clearProduct" class="iviewIptWidth250">
            <Option v-for="(item, index) in productSelect" :value="item.product_name" :key="index" :label="item.product_name">{{ item.product_name }}</Option>
          </Select>
        </FormItem> -->
        <FormItem class="marginLeft40">
        <span>产品名称：</span>
        <Select placeholder="请选择" v-model="queryFrom.product_name" clearable filterable class="iviewIptWidth250" @on-change="productChange">
          <Option v-for="(item, index) in productList" :value="item.product_name" :key="index">{{ item.product_name }}</Option>
        </Select>
      </FormItem>
       <!-- <FormItem class="marginLeft40">
          <span>规格型号：</span>
          <Select placeholder="请选择" v-model="queryFrom.specification_id_str" :disabled="!queryFrom.product_name" clearable filterable multiple :max-tag-count="queryFrom.specification_id_str.length == 1 ? 1 : 0" class="iviewIptWidth250">
           <Option v-for="(item, index) in SKUSelect" :value="item.specification_id" :key="index" :label="item.model_name + '-' + item.item_number">
            <span>{{ item.model_name }}</span>
            <span style="float: right; color: #ccc; margin-right: 20px;">{{ item.item_number }}</span>
          </Option>
          </Select>
        </FormItem> -->
         <FormItem class="marginLeft40">
        <span>规格型号/SKU：</span>
        <Select placeholder="请选择" v-model="queryFrom.specification_id_str" :disabled="!queryFrom.product_id && !(queryFrom.product_id == 0)" clearable filterable multiple :max-tag-count="queryFrom.specification_id_str.length == 1 ? 1 : 0" class="iviewIptWidth250">
          <Option v-for="(item, index) in productModelList" :value="item.code_id" :key="index" :label="item.model_name + '-' + item.item_number">
            <span>{{ item.model_name }}</span>
            <span style="float: right; color: #ccc; margin-right: 20px;">{{ item.item_number }}</span>
          </Option>
        </Select>
      </FormItem>
        <FormItem class="marginLeft40">
          <span>出库单号：</span>
          <Input placeholder="请输入" v-model="queryFrom.wd_order_number" class="iviewIptWidth250" />
        </FormItem>
        <!-- 按钮 -->
        <FormItem class="po-create-number marginRight0" :label-width="10">
          <span class="pageBtn finger btnSure" @click="query">查询</span>
        </FormItem>
      </Form>
      <!-- 表格 -->
      <div class="table">
        <Table ref="selection" :productList="listColumns" :productData="listData" :total="total" :pages="pages" @on-select-all-cancel="handleSelectAllCancel" @on-select-all="handleSelectAll" @on-select="selectionList" @on-select-cancel="cnacelSelectionList"   @change-page="changePage" :loading="isLoad">
           <template slot-scope="{ row,index }" slot="nowAmount">
              <div class="changeClass">
                <div>
                  <Input placeholder="请输入" :disabled='row._checked' @on-change="changeQuan($event,index,row.quantity)" v-model.trim="row.nowAmount"></Input>
                </div>
              </div>
          </template>
        </Table>
        <div class="jineBox" v-if="selectionTableList.length > 0">
          <span class='jinelit'>可开票数量：{{allNum}}</span>
          <span class='jinelit'>可开票金额：{{allPrice}}</span>
          <span class='jinelit'>本次开票数量：{{totalnowAmount}}</span>
          <span>本次开票金额：{{totalnowQuantity}}</span>
        </div>
        <div class="btnClass">
          <span class="pageBtn finger btnSure" @click="createFp">创建发票</span>
          <span class="pageBtn finger btnCancle" @click="back">返回</span>
        </div>
      </div>
    </div>
    <Modal v-model="selectionTableStatus" title="创建发票" label-position="left" width="550" :footer-hide="true" :mask-closable="false" :closable="false" class-name="vertical-center-modal">
      <div class="table">
       <table class="contentBox">
         <th class="contentCbox">
           <td class="left">发票单号</td>
           <td class="right">
             {{contentBoxObj.todayTime}}
           </td>
         </th>
         <th class="contentCbox">
            <td class="left">发票代码</td>
           <td class="right">
             <Input placeholder="请输入" maxlength="50" @on-keyup='zhengze($event,1)' v-model="contentBoxObj.invoice_code"/>
           </td>
         </th>
         <th class="contentCbox">
            <td class="left">发票号码</td>
           <td class="right">
             <Input placeholder="请输入" maxlength="50" @on-keyup='zhengze($event,2)' v-model="contentBoxObj.invoice_number"/>
           </td>
         </th>
         <th class="contentCbox">
            <td class="left">开票时间</td>
           <td class="right">
             <DatePicker type="date" placeholder="选择时间" v-model="contentBoxObj.invoice_time" style="width: 100%"></DatePicker>
           </td>
         </th>
         <th class="contentCbox">
            <td class="left">本次开票金额</td>
           <td class="right">
             <Input placeholder="请输入" v-model="totalnowQuantity" readonly />
           </td>
         </th>
         <th class="contentCbox">
            <td class="left">发票</td>
           <td class="right">
             <div class="item borderBtm">
              <div class="body">
               <div class="uploading">
                 <div class="chaoguo">
                    <span v-for="(item, index) in debitNote" :key="index">
                     <a @click="openFile(item.invoice_url)">{{ item.invoice_name }}</a>
                     <i @click="deleteImage(index)" v-if="!readonly">x</i>
                    </span>
                 </div>
                 <i class="tip" v-if="debitNote && debitNote.length == 0">只允许上传jpg,jpeg,png,pdf格式的文件</i>
                 <FormUpload class="upload finger" label="上传" :multiple="true" @get-key="getKey(...arguments)"></FormUpload>
                </div>
              </div>
            </div>
           </td>
         </th>
          <th class="contentCbox">
            <td class="left">备注</td>
           <td class="right borderBom">
             <Input placeholder="请输入" type="textarea" :autosize="{minRows: 2,maxRows: 5}" v-model="contentBoxObj.note" maxlength="50" />
           </td>
         </th>
       </table>
      </div>
      <div class="foot">
        <span class="pageBtn finger btnCancle" @click="selectionTableStatus = false">取消</span>
        <span class="pageBtn finger marginLeft20 btnSure" @click="sureTable">确定</span>
      </div>
    </Modal>
  </div>
</template>

<script>
import Table from '@/components/table'
import FormUpload from '../../components/KehuformUpload.vue'
export default {
  name:'choiseListTableKehu',
    data() {
    return {
      productModelList:[],
      savePageContent:[],
      readonly: false,
      debitNote: [],
      totalnowAmount:0,
      totalnowAmountNum:0,
      totalnowQuantity:0,
      allNum: 0,
      allPrice: 0,
      suibianba:'',
      contentBoxObj:{
        invoice_time:'',
        todayTime:'',
        supplier_id:'',
        invoice_info:[],
        invoice_amount:'',
        invoice_lines:[],
        invoice_code:'',
        invoice_number:'',
      },
      selectionTableStatus:false,
      selectionTableList:[],
      productSelect:[],
      SKUSelect: [],
      productList: [],
      isLoad: true,
      // 表格数据
      listData: [],
      // 表格头部标题
      listColumns: [
        {
          title: '序号',
          type: 'selection',
          align: 'center',
          minWidth: 75,
          maxWidth: 90,
        },
        {
          title: '产品编号',
          key: 'product_model_code',
          align: 'center',
          minWidth: 130,
        },
        {
          title: '产品名称',
          key: 'product_name',
          align: 'center',
          minWidth: 150,
        },
        {
          title: '规格型号',
          key: 'model_name',
          align: 'center',
          minWidth: 100,
        },
        {
          title: '单位',
          key: 'unit',
          align: 'center',
          minWidth: 80,
        },
        {
          title: '采购单价',
          key: 'unit_price',
          align: 'center',
          minWidth: 100,
          render: (h, param) => {
            let str = ''
            if (param.row.unit_price == null) {
              str = ''
            } else {
              str = this.$utils.formatMoney(param.row.unit_price, true)
            }
            return h('span', str)
          },
        },
        {
          title: '可开票数量',
          key: 'quantity',
          align: 'center',
          minWidth: 80,
        },
        {
          title: '可开票金额',
          key: 'amount',
          align: 'center',
          minWidth: 100,
          render: (h, param) => {
            let str = ''
            if (param.row.amount == null) {
              str = ''
            } else {
              str = this.$utils.formatMoney(param.row.unit_price * param.row.quantity, true)
            }
            return h('span', str)
          },
        },
        {
          title: '本次开票数量',
          slot:'nowAmount',
          align: 'center',
          minWidth: 100,
        },
        {
          title: '本次开票金额',
          key: 'amount',
          align: 'center',
          minWidth: 100,
           render: (h, param) => {
            let str = ''
            if (param.row.amount == null) {
              str = ''
            } else {
              str = this.$utils.formatMoney(param.row.unit_price * param.row.nowAmount, true)
            }
            return h('span', str)
          },
        },
        {
          title: '批号',
          key: 'batch_number',
          align: 'center',
          minWidth: 100,
        },
        {
          title: '有效期',
          key: 'valid_period',
          align: 'center',
          minWidth: 100,
          render: (h, param) => {
            return h('div', [
              h(
                'span',{},
                param.row.valid_period ? this.$moment(param.row.valid_period * 1000).format('YYYY-MM-DD') : ''
              ),
            ])
          },
        },
        {
          title: '销售单号',
          key: 'so_order_number',
          align: 'center',
          minWidth: 120,
        },
         {
          title: '出库单号',
          key: 'wd_order_number',
          align: 'center',
          minWidth: 120,
        },
      ],
      // 表单拉下选项
      thirdList: [],
      gongyingshangList: [],
      kehuList: [],
      // 查询参数
      queryFrom: {
        product_model_code: '',
        product_name: '',
        specification_id_str: '',
        po_order_number: '',
      },
      // 页码
      pages: {
        page: 1,
        rows: 10,
      },
      // 总条数
      total: 1,
      // 合计金额
      totleMoney: 0,
      modelList:[],
      todayTime:'',
      selectAll: false
    }
    },
    components:{
        Table,
        FormUpload
    },
    created(){
        this.getProductList()
    },
    methods: {
      handleSelectAllCancel(){
        this.listData.forEach(item => { item._checked = false})
        this.savePageContent.forEach(element => {
          element.content.forEach(item => { item._checked = false });
        });
        this.selectionTableList = []
      },
      handleSelectAll(){
        this.isLoad = true
        let params = {
          page: 1,
          rows: 99999,
        }
        Object.assign(params, this.queryFrom)
        this.$http.get(this.$api.invoiceWarehouseDeliveryDetailLook, params, true).then(res => {
          res.data.ck_lines.forEach( item => {
            item.nowAmount = item.quantity
            item._checked = true
          })
          this.selectionTableList = res.data.ck_lines
          let index = 0;
          let newArray = [];
          while(index < res.data.ck_lines.length) {
              newArray.push({
                content: JSON.parse(JSON.stringify(res.data.ck_lines.slice(index, index += 10))),
                page: newArray.length + 1
              });
          }
          this.savePageContent = newArray
          this.listData.forEach(item => { item._checked = true})
          this.sumTotal()
          this.isLoad = false
        })
      },
        // 产品名称 下拉
    getProductList() {
      let data = {
        customer_type: this.$route.query.customer_type,
        customer_id: this.$route.query.customer_id,
      }
      this.$http.get(this.$api.productsSku, data).then(res => {
        this.productList = res.data
      })
    },
       // 产品名称改变
    productChange(e) {
      this.productModelList = []
      this.queryFrom.specification_id_str = ''
      if (!e) return
      this.productList.forEach(item => {
        if (item.product_name == e) {
          this.queryFrom.product_id = item.product_id
        } else {
          this.queryFrom.product_id = ''
        }
      })
      // 规格/SKU下拉
      let data = {
        product_name: e,
        customer_type: this.queryFrom.customer_type,
        customer_id: this.queryFrom.customer_id,
      }
      this.$http.get(this.$api.productsSku, data).then(res => {
        this.productModelList = res.data
      })
    },
    getDetail(id){
      this.$http.get(this.$api.supplierInvoiceDetail, {invoice_id:id}, true).then(res => {
      
      })
    },
    getKey(key, file_name, url) {
      this.debitNote.push({
        invoice_key: key,
        // invoice_url: url,
        invoice_name: file_name,
      })
    },
      // 图片查看
    openFile(url) {
      window.open(url)
    },
    // 删除图片
    deleteImage(index) {
      this.debitNote.splice(index, 1)
    },
    sureTable(){
      if(!this.contentBoxObj.invoice_code){
        this.$Message.warning(`请填写发票代码`)
          return
      }
      if(!this.contentBoxObj.invoice_number){
        this.$Message.warning(`请填写发票号码`)
          return
      }
      if(!this.contentBoxObj.invoice_time){
        this.$Message.warning(`请填写开票时间`)
          return
      }
      if(this.debitNote.length == 0){
        this.$Message.warning(`请上传发票`)
          return
      }
      let arr =[]
      this.selectionTableList.forEach(item=>{
        arr.push({id:item.id,invoice_quantity:item.nowAmount})
      })
      let data ={
        invoice_no : this.contentBoxObj.todayTime,
        invoice_code : this.contentBoxObj.invoice_code,
        invoice_number : this.contentBoxObj.invoice_number,
        // invoice_lines : JSON.stringify(arr),
        invoice_lines : arr,
        invoice_time : this.$moment(this.contentBoxObj.invoice_time).format('YYYY-MM-DD'),
        invoice_amount: this.contentBoxObj.invoice_amount,
        customer_id : this.$route.query.customer_id,
        note:this.contentBoxObj.note,
        customer_type: this.$route.query.customer_type,
        // invoice_info:JSON.stringify(this.debitNote)
        invoice_info:this.debitNote
      }
      this.$http.post(this.$api.invoiceManage, data).then(res => {
          if (res.status) {
             this.$Message.success(`创建成功`)
             this.selectionTableStatus = false
               this.$router.push({
             path: '/invoiceManage',
             query:{
               clickType :'2'
             }
           })
          }
        })
    },
    changeQuan(e,index,num){
      if(Number(e.target.value)>num) {
        this.listData[index].nowAmount = num
        this.$Message.warning(`开票数量不可大于可开票数量`)
        return
      }
      this.$set(this.listData[index], 'nowAmount', Number(e.target.value))
      this.$set(this.listData[index], 'amount', Number( this.listData[index].unit_price * e.target.value))
    },
    zhengze(e,num){
      if(num == 1){
        this.contentBoxObj.invoice_code = e.target.value.replace(/[^\w\.\/]/ig,'')
      }else {
        this.contentBoxObj.invoice_number = e.target.value.replace(/[^\w\.\/]/ig,'')
      }
    },
    cnacelSelectionList(e,row){
      if(!e) return false
         this.listData.forEach(item=>{
        if(item.id == row.id){
          item._checked = false
        }
        })
        let index = this.selectionTableList.length
        while (index--) {
            if (this.selectionTableList[index].id == row.id) {
                this.selectionTableList.splice(index, 1);
            }
        }
        this.sumTotal()
    },
    sumTotal(){
      let total = 0
      let money = 0
      let allNum = 0
      let allPrice = 0
      this.selectionTableList.forEach(item=>{
        total += item.nowAmount
        money += item.amount
        allNum += item.quantity
        allPrice += item.quantity * item.unit_price
      })
      this.totalnowAmount = total
      this.totalnowAmountNum = money
      this.totalnowQuantity=this.$utils.formatMoney(money,true)
      this.allNum = allNum
      this.allPrice = this.$utils.formatMoney(allPrice,true)
    },
    selectionList(e,row){
        if(!e) return false
         this.listData.forEach(item=>{
        if(item.id == row.id){
          item._checked = true
        }
        })
        this.selectionTableList.push(row)
        this.sumTotal()
    },
    createFp(){
      if(this.selectionTableList.length == 0) return this.$Message.warning(`请勾选发票`)
      // 开启弹窗
      // 截取当天时间
      this.contentBoxObj = {
        invoice_time:'',
        todayTime:'',
        supplier_id:'',
        invoice_info:[],
        invoice_amount:'',
        invoice_lines:[],
        invoice_code:'',
        invoice_number:'',
      }
      this.contentBoxObj.todayTime ='XX' + this.$moment(new Date()).format('YYYYMMDDHHmmss')
      this.contentBoxObj.invoice_amount = this.totalnowAmountNum
      this.selectionTableStatus = true
    },
    back(){
        this.$refs.selection.$refs.table.selectAll(false);
        window.history.go(-1)
    },
    getSupply() {
      if (this.$route.query.customer_id) {
        let data = {
          customer_id: this.$route.query.customer_id,
        }
        this.$http.get(this.$api.warehouseReceiptProductsSkuList, data).then(res => {
          if (res.status) {
            this.productSelect = res.data
          }
        })
      }
    },
     clearProduct() {
      this.queryFrom.product_name = null
      this.queryFrom.specification_id_str = ''
      this.SKUSelect = []
    },
      // 获取产品规格下拉
    getSKUSelect(val) {
      if (this.queryFrom.supplier_id && this.queryFrom.product_name) {
        this.productDisable = false
        let data = {
          supplier_id: this.queryFrom.supplier_id,
          product_name: val,
        }
        this.$http.get(this.$api.warehouseReceiptProductsSkuList, data).then(res => {
          this.SKUSelect = res.data
          this.queryFrom.specification_id_str = ''
        })
      }
    },
    // 获取供应商下指定产品下的规格型号
    queryProductCodeSelect(name) {
      this.$http.get(this.$api.receiptPrductModelName, { product_name: name }, false).then(res => {
        this.modelList = res.data.model_name_list
      })
    },
    // 查询
    query() {
      this.isLoad = true
      this.pages.page = 1
      this.savePageContent= []
      this.selectionTableList = []
      this.getInvoiceManage()
    },
    // 获取发票列表
    async getInvoiceManage() {
      let data = {}
      let result = {}
      Object.assign(data, this.pages, this.queryFrom)
      result = await this.$http.get(this.$api.invoiceWarehouseDeliveryDetailLook, data, true)
      this.listData = result.data.ck_lines
      this.listData.forEach((item,index) => {
        this.$set(this.listData[index],'nowAmount',item.quantity)
      })
      this.total = result.data.total
      this.isLoad = false
    },
    getSupplierNameList() {
      this.$http.get(this.$api.getSupplierNameList).then(res => {
        this.thirdList = res.data
        this.gongyingshangList = res.data
      })
    },
    // 获取客户名称下拉列表
    getCustomerList() {
      this.$http.get(this.$api.customerList).then(res => {
        this.thirdList = res.data
        this.kehuList = res.data
      })
    },
    // 分页改变
    changePage(e) {
      // 翻页保存当前页面逻辑,分页前先遍历查询是否又当前页，有就不走接口,保存在savePageContent中
      if(e){
        // 切换页面就保存当前的listDate
        let data = {
          content:JSON.parse(JSON.stringify(this.listData)),
          page:this.pages.page
        }
        let fuzhi = false
        this.savePageContent.forEach(item=>{
          if(item.page == data.page){
            fuzhi = true
            item.content = data.content
          }
        })
        if(!fuzhi){
          this.savePageContent.push(data)
        }
        // 页面和数组的关系
        let shuzu = false
        this.savePageContent.forEach(item=>{
          if(item.page == e){
            shuzu = true
            this.pages.page = e
            this.listData = item.content
          }
        })
        if(!shuzu){
          this.pages.page = e
          this.getInvoiceManage()
        }
      }
    }
    },
    activated(){
      if (this.$route.query.customer_id) {
            this.queryFrom.customer_id = this.$route.query.customer_id
        }
      if (this.$route.query.customer_type) {
          this.queryFrom.customer_type = this.$route.query.customer_type
      }
      this.query()
    },
    mounted() {
      if (this.$route.query.customer_id) {
            this.queryFrom.customer_id = this.$route.query.customer_id
        }
      if (this.$route.query.customer_type) {
          this.queryFrom.customer_type = this.$route.query.customer_type
      }
      this.query()
    },
    beforeDestroy(){
      this.savePageContent = []
      this.selectionTableList = []
    }
}
</script>

<style lang="less" scoped>
.btnClass {
    span {
        float: right;
        margin-left: 10px;
    }
}
.contentBox {
  .contentCbox {
    min-height: 50px;
    margin-top: -2px;
    display: flex;
  }
  .left {
    background-color: #F8F8F9;
    width: 130px;
    border: 1px solid #ccc;
    padding: 5px 10px;
    display: flex;
    align-items: center;
  }
  .right{
    width: 390px;
    border: 1px solid #ccc;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    border-left: none;
    border-bottom: none;
  }
}
.foot {
  margin-top: 20px;
  text-align: right;
}
.table {
  position: relative;
}
.borderBom {
  border-bottom: 1px solid #ccc!important;
}
.table {
    width: 100%;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    color: #525b6d;
    .newItem {
      display: flex;
      border: 1px solid #e8eaec;
      border-bottom: 0;
      .title {
        min-height: 50px;
        width: 200px;
        padding-left: 30px;
        background: #f8f8f9;
        border-right: 1px solid #e8eaec;
        display: flex;
        align-items: center;
      }
      .body {
        padding-left: 20px;
        display: flex;
        align-items: center;
        margin: 7px 0;
        .width1000 {
          width: 1000px;
        }
        .uploading {
          min-height: 36px;
          line-height: 36px;
          border: 1px solid #e3e3e3;
          border-radius: 4px;
          font-size: 14px;
          position: relative;
          padding-left: 7px;

          display: flex;
          flex-wrap: wrap;

          .tip {
            font-size: 14px;
            padding: 0 0 0 4px;
            color: #c5c8ce;
            cursor: default;
            user-select: none;
          }
          .itemSpan {
            border: 1px solid #dddddd;
            border-radius: 13px;
            padding: 1px 10px;
            margin: 3px 10px 3px 0;
            // cursor: pointer;
            span {
              border: 0;
              text-decoration: none;
              color: #333333;
              max-width: 80px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            i {
              font-style: normal;
              padding-left: 10px;
              cursor: pointer;
            }
          }
        }
      }
    }
    .item {
      width: 100%;
      display: flex;
      height: 50px;
      line-height: 50px;
      .body {
        display: flex;
        width: 100%;
        align-items: center;
        .uploading {
          text-align: left;
          padding-left: 4px;
          width: 100%;
          height: 36px;
          line-height: 36px;
          border: 1px solid #e3e3e3;
          border-radius: 4px;
          font-size: 14px;
          position: relative;
          .tip {
            font-size: 14px;
            color: #c5c8ce;
            cursor: default;
            user-select: none;
          }
          .upload {
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: 999;
            width: 52px;
            height: 100%;
            background-color: #f4f4f4;
            border-left: 1px solid #e3e3e3;
            float: right;
            text-align: center;
            font-size: 14px;
          }

          span {
            border: 1px solid #dddddd;
            border-radius: 13px;
            padding: 1px 10px;
            margin-right: 10px;
            cursor: pointer;

            a {
              text-decoration: none;
              color: #333333;
              max-width: 80px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            i {
              font-style: normal;
              padding-left: 10px;
            }
          }
        }

        .width300 {
          width: 300px;
        }

        .width1000 {
          width: 1000px !important;
        }
      }
    }
    p {
      font-size: 18px;
      font-weight: bold;
      color: #525b6d;
      margin: 20px 0;
    }
  }
  .chaoguo{
    overflow: hidden;
  }
  .jineBox {
  position: absolute;
  bottom: 45px;
  right: 0px;
  z-index: 999;
  font-weight: 600;
  font-size: 16px;
}
.jinelit {
  margin-right: 100px;
}
</style>